<template>
<div class="ny_pad">
	<div class="menu_ico">
	 <top_menu></top_menu>
	 </div>
<!-- about_lay tz-->
	 <div class="" id="dj1">
	   <div class="about_wapper">
       <img :src="imgb" />
	   </div>
	 </div>


  <div class="container">
    <div class="row pd10"><img src="@/assets/images/sy.png" /></div>
    <div class="">
      <div class="row">
        <div class="col-lg-12">


          <dl class="sousuo_list" style="width: 70%;margin: 20px auto; display: block;border-bottom: none;">
            <form @submit.prevent="syysousuo">
              <div class="input-group ">
                <input type="text" maxlength="50" id="scode" name="scode" v-model="scode"  class="form-control">
                <span class="input-group-btn">
                        <input type="submit" value="验证产品" class="btn btn-default" />
                    </span>
              </div>
            </form>
          </dl>

          <dl class="sousuo_list">
            <dt>温馨提示:</dt>
            <dd>自2021年8月27日(含）之后生产的产品，可使用奶粉罐罐体侧面二维码扫码进行追溯信息查询，感谢您的对辉山奶粉的关注。</dd>
          </dl>


          <dl class="sousuo_list">
            <dt>功能提示:</dt>
            <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-6">1.2021年12月15日前生产的产品:
              输入您商品包装底部的批次号,如何查询批次号?点击验证产品按钮即可在线验证完成产品
              追溯。</div>
            <div  class="col-lg-4 col-md-4 col-sm-6"><img  src="@/assets/images/s1.png" style="max-width: 100%;margin-top: -60px;"/></div>
            </div>
            <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-6">2.2021年12月15日后生产的产品:
              打开微信扫一扫,扫描罐底二维码即可实现产品追溯。也可通过输入罐底批号,点击验证产品按钮,即可在线验证完成产品追溯。 </div>
            <div class="col-lg-4 col-md-4 col-sm-6"><img  src="@/assets/images/s2.png" style="max-width: 100%;margin-top: -60px;"/></div>
            </div>
          </dl>



          <!-- Corpix Marketing Content End -->
        </div>

      </div>
    </div>
  </div>


	 <fo_oter></fo_oter>
	<!-- footer -->
</div>
</template>

<script>
import top_menu from '../components/menu.vue'
import fo_oter from '../components/footer.vue'
import "@/assets/js/jquery-1.9.1.min.js";
import "@/assets/bootstrap/bootstrap.min.js";
import {GETDATA1,GETDATA2,GETDATA3,GETDATA4} from '../apis/dj.js'
import {GETADDATA, GETLISTDATA} from "@/apis/api";
export default {
  name: 'dj',
  components: {
    top_menu,fo_oter
  },
  data() {
    return {
		testdata:[],
		testdata1:[],
		testdata2:[],
		testdata3:[],
      imgb:""
    };
  },
  mounted() {
	  this.onloading();
  },
  methods: {
  	  async onloading(){
        GETLISTDATA({catid:61,pageNum:1,pageSize:6}).then((res) => {
          if(res.code == 200){
            this.testdata = res.rows;
          }
        });
        GETADDATA({id:5}).then((res) => {
          if(res.code == 200){
            this.imgb = res.data.content[0].source;
          }
        });
        GETLISTDATA({catid:62,pageNum:1,pageSize:6}).then((res) => {
          if(res.code == 200){
            this.testdata1 = res.rows;
          }
        });

  	  },
      syysousuo(event) {
        if (this.scode) {
          window.location.href = "/searchlist?code=" + this.scode;
        }
      }
  }
}
</script>